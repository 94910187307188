/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Load Events
    $(document).ready(function() {
        var $window = $(window),
            screenWidth = $( window ).width();

        if(screenWidth <= 768) {
            $('body').addClass('in-phone');
        }else {
            $('body').removeClass('in-phone');
        }

        $( window ).resize(function() {
            var screenWidth = $( window ).width();
            if(screenWidth <= 768) {
                $('body').addClass('in-phone');
            }else {
                $('body').removeClass('in-phone');
                $('.main-nav-ctn').removeClass('open');
            }
            //Close menu when reszing
            // if($('#menu-btn').hasClass("open")) {
            //     $('#menu-btn').trigger('click');
            //     $('header').removeClass('menu-open');
            //     $( ".menu-item-has-children a" ).each(function( index ) {
            //         $(this).removeClass('menu-active');
            //         $(this).siblings('.sub-menu').removeClass('open-sub-menu');
            //     });
            // }
        });

        $('#menu-btn').on('click', function () {
            $(this).toggleClass('open');
            $('header').toggleClass('menu-open');
            $( ".menu-item-has-children a" ).each(function( index ) {
                $(this).removeClass('menu-active');
                $(this).siblings('.sub-menu').removeClass('open-sub-menu');
            });
        });

        $('.menu-item-has-children a').on('click', function () {
            if($('body').hasClass('in-phone')){
                $(this).toggleClass('menu-active');
                $(this).siblings('.sub-menu').toggleClass('open-sub-menu');
            }
        });

        $('#menu-nav-main .menu-item-has-children > a').on('click', function (e) {
            if(window.innerWidth < 768){
                e.preventDefault();
                $('.sub-menu-navigation').addClass('active-sub-menu');
                if( !$('body').hasClass('in-phone')) {
                    $('.sub-menu-navigation').addClass('active-sub-menu');

                    var menuID = $(this).attr('href');
                    if($(this).parent().hasClass('link-badge')){
                        menuID = "#badge";
                    }
                    $('.open-sub-menu').removeClass('open-sub-menu');
                    $(menuID).addClass('open-sub-menu');
                }
            }

        });

            $('#menu-nav-main a').hover(
                function() {
                    if(window.innerWidth >= 768){
                        if( !$(this).hasClass('menu-item-has-children')) {
                            $('.open-sub-menu').removeClass('open-sub-menu');
                        }
                    }
                }
            );

            $('.top-bar-ctn').hover(
                function() {
                    if(window.innerWidth >= 768){
                        if( !$(this).hasClass('menu-item-has-children')) {
                            $('.open-sub-menu').removeClass('open-sub-menu');
                        }
                    }
                }
            );


            $('#menu-nav-main .menu-item-has-children a').hover(
                function() {
                    if( !$('body').hasClass('in-phone')) {
                        var menuID = $(this).attr('href');
                        if($(this).parent().hasClass('link-badge')){
                            menuID = "#badge";
                        }
                        $('.open-sub-menu').removeClass('open-sub-menu');
                        $(menuID).addClass('open-sub-menu');
                    }
                }
            );

            $('.sub-menu-navigation').hover(
                function() {
                    if( !$('body').hasClass('in-phone')) {
                        $('.open-sub-menu').addClass('open-sub-menu');
                    }
                }, function() {
                    if( !$('body').hasClass('in-phone')) {
                        $('.open-sub-menu').removeClass('open-sub-menu');
                    }
                }
            );


        $('.close-btn').on('click', function () {
            $('.sub-menu-navigation').removeClass('active-sub-menu');
            $('.open-sub-menu').removeClass('open-sub-menu');
        });


        //Function to add the CARRET beside the link with sub menu
        function appendElement($elemToAppend, $target, $classToAdd){
            $($elemToAppend).appendTo($target);
            $($elemToAppend).addClass($classToAdd);
        }
        appendElement( $('<img class="arrow-sub-menu show-phone-only"  src="/app/themes/badge-factor-theme/dist/images/icons/menu-dropdown-arrow.png" />' ),'.menu-item-has-children > a','link-carret');

        //AJAX
        $('body').on('click', '.pdf-private-status', function(e) {
            var button = $(this);
            $.ajax({
                url: MyAjax.url,
                method: 'POST',
                data: {
                    action: 'toggle_pdf_private_status',
                    submission_id: button.data('submission-id'),
                    nonce: MyAjax.nonce
                },
                beforeSend: function () {
                    button.removeClass('public').removeClass('private').removeClass('success').removeClass('failed').removeClass('loading').addClass('loading');
                }
            }).done(function(response) {
                if (response.success && response.status !== false) {
                    button.removeClass('public').removeClass('private').removeClass('success').removeClass('failed').removeClass('loading').addClass(response.status).addClass('success');
                    if (response.status === 'private') {
                        button.find('.glyphicon').removeClass('glyphicon-eye-open').addClass('glyphicon-eye-close');
                    } else {
                        button.find('.glyphicon').removeClass('glyphicon-eye-close').addClass('glyphicon-eye-open');
                    }
                }
            }).fail(function(){
                button.removeClass('public').removeClass('private').removeClass('success').removeClass('failed').removeClass('loading').addClass('failed');
            });
        });

        $('body').on('click', '.user-profile-private-status', function(e) {
            var button = $(this);
            $.ajax({
                url: MyAjax.url,
                method: 'POST',
                data: {
                    action: 'toggle_user_profile_private_status',
                    user_id: button.data('user-id'),
                    nonce: MyAjax.nonce
                },
                beforeSend: function () {
                    button.removeClass('public').removeClass('private').removeClass('success').removeClass('failed').removeClass('loading').addClass('loading');
                }
            }).done(function(response) {
                if (response.success && response.status !== false) {
                    button.removeClass('public').removeClass('private').removeClass('success').removeClass('failed').removeClass('loading').addClass(response.status).addClass('success');
                    if (response.status === 'private') {
                        button.find('.glyphicon').removeClass('glyphicon-eye-open').addClass('glyphicon-eye-close');
                    } else {
                        button.find('.glyphicon').removeClass('glyphicon-eye-close').addClass('glyphicon-eye-open');
                    }
                }
            }).fail(function(){
                button.removeClass('public').removeClass('private').removeClass('success').removeClass('failed').removeClass('loading').addClass('failed');
            });
        });

        $('#btn-reveal-pw').on('click',function () {
            var input = $('#user_pass');
            switch(input.attr('type')){
                case 'password':
                    input.attr('type','text');
                    $(this).html('Cacher le mot de passe');
                    break;
                case 'text':
                    input.attr('type','password');
                    $(this).html('Voir le mot de passe');
                    break;
                default:
                    input.attr('type','password');
                    $(this).html('Cacher le mot de passe');
                    break;
            }
        });
        $('.code-share').on('click',function (e) {
            e.preventDefault();
            $('#iframe-code').focus().select();
            document.execCommand('copy');
            $('#msg-copied').fadeIn(500).delay(2000).fadeOut(500);
        });
    });
}(jQuery));